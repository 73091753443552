import * as RatingActions from './rating.actions';

const initialState = {
    averageRating: 0
};

export const ratingReducer = (state = initialState, action: RatingActions.RatingActions) => {
    switch(action.type) {
        case RatingActions.SET_AVERAGE_RATING:
            return {...state, averageRating: action.payload};
        default:
            return {...state};
    }
};
