export class TokenService {
    public constructor(
        private userData: any

    ) {}

    public getAuthToken() {
        if (!this.userData.authTokenExpirationTime || this.userData.authTokenExpirationTime <= new Date().getTime()) {
            return null;
        }
        return this.userData.accessToken;
    }

    public getAuthTokenDuration() {
        if (!this.getAuthToken()) {
            return 0;
        }

        return this.userData.authTokenExpirationTime - new Date().getTime();
    }
}

