import { Action } from '@ngrx/store';

export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const REMOVE_AUTH_DATA = 'REMOVE_AUTH_DATA';

export class SetAuthData implements Action {
  public readonly type = SET_AUTH_DATA;
  public constructor(public payload: any){}
}

export class RemoveAuthData implements Action {
  public readonly type = REMOVE_AUTH_DATA;
  public constructor(){}
}

export type AuthActions = SetAuthData | RemoveAuthData;

