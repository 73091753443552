import { Action } from '@ngrx/store';

export const ADD_CART_TOTAL = 'ADD_CART_TOTAL';
export const DELETE_CART_TOTAL = 'ADD_CART_TOTAL';

export class AddCartTotal implements Action {
  public readonly type = ADD_CART_TOTAL;
  public constructor(public payload: number) {}
}

export class DeleteCartTotal implements Action {
  public readonly type = DELETE_CART_TOTAL;
  public constructor(public payload: number) {}
}

export type CartActions = AddCartTotal | DeleteCartTotal;
