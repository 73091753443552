import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardUtil } from './common/utils/auth.guard.util';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canLoad: [AuthGuardUtil]
  },
  {
    path: 'cause',
    loadChildren: () => import('./cause/cause.module').then( m => m.CausePageModule),
    canLoad: [AuthGuardUtil]
  },{
    path: 'fixit',
    loadChildren: () => import('./fixit/fixit.module').then( m => m.FixitPageModule),
    canLoad: [AuthGuardUtil]
  },{
    path: 'group',
    loadChildren: () => import('./group/group.module').then( m => m.GroupPageModule),
    canLoad: [AuthGuardUtil]
  },{
    path: 'org',
    loadChildren: () => import('./org/org.module').then( m => m.OrgPageModule),
    canLoad: [AuthGuardUtil]
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'legacy',
    loadChildren: () => import('./legacy/legacy.module').then( m => m.LegacyPageModule),
    canLoad: [AuthGuardUtil]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canLoad: [AuthGuardUtil]
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule),
    canLoad: [AuthGuardUtil]
  },  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule),
    canLoad: [AuthGuardUtil]
  },{
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule),
    canLoad: [AuthGuardUtil]
  },
  {
    path: 'wish-list',
    loadChildren: () => import('./wish-list/wish-list.module').then( m => m.WishListPageModule),
    canLoad: [AuthGuardUtil]
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then( m => m.OrderPageModule),
    canLoad: [AuthGuardUtil]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then( m => m.CheckoutPageModule),
    canLoad: [AuthGuardUtil]
  },
  {
    path: 'address',
    loadChildren: () => import('./address/address.module').then( m => m.AddressPageModule),
    canLoad: [AuthGuardUtil]
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule),
    canLoad: [AuthGuardUtil]
  },{
    path: 'invite/:token',
    loadChildren: () => import('./invite/invite.module').then( m => m.InvitePageModule),
    canLoad: [AuthGuardUtil]
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'toc',
    loadChildren: () => import('./toc/toc.module').then( m => m.TocPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
