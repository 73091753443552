import * as AuthActions from './auth.actions';

const initialState = {
  userIsAuthenticated: false,
  userData: null
}

export const authReducer = (state = initialState, action: AuthActions.AuthActions) => {
  switch(action.type) {
    case AuthActions.SET_AUTH_DATA:
      return {...state, userIsAuthenticated: true, userData: action.payload};
      break;
    case AuthActions.REMOVE_AUTH_DATA:
      return {...state, userIsAuthenticated: false, userData: null};
      break;
    default:
      return { ...state };
  }
}