import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable, of} from 'rxjs';
import { take, tap, switchMap } from 'rxjs/operators';
import { AuthServiceUtil } from './auth.service.util';
import { ConfigUtil } from './config.util';

@Injectable({
    providedIn: 'root'
})

export class AuthGuardUtil implements CanLoad {
    public constructor(
        private authServiceUtil: AuthServiceUtil,
        private router: Router
    ) {}

    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.authServiceUtil.userIsAuthenticated().pipe(
            take(1),
            switchMap(isAuthenticated => {
                if (!isAuthenticated) {
                    return this.authServiceUtil.autoLogin();
                } else {
                    return of(isAuthenticated);
                }
            }),
            tap(isAuthenticated => {
                if (!isAuthenticated) {
                  const navigation = this.router.getCurrentNavigation();
                  let returnUrl = '/';
                  if (navigation) {
                    returnUrl = navigation.extractedUrl.toString();
                  }
                   this.router.navigate([`${ConfigUtil.authUrl}`], {queryParams: {returnUrl: returnUrl, param: segments[0]?.path}});
                }
            })
        );
    }
}
