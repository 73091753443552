import * as WishlistActions from './wishlist.actions';

const initialState = {
  wishListTotal: 0,
};

export const wishlistReducer = (state = initialState, action: WishlistActions.WishlistActions) => {
  switch(action.type) {
    case WishlistActions.ADD_WISHLIST_TOTAL:
      return {...state, wishListTotal: action.payload};
      break;
    case WishlistActions.DELETE_WISHLIST_TOTAL:
        return {...state, wishListTotal: action.payload};
        break;
    default:
      return {...state};
  }
};
