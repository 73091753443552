import { Action } from '@ngrx/store';

export const SET_AVERAGE_RATING = 'SET_AVERAGE_RATING';

export class SetAverageRating implements Action {
    public readonly type = SET_AVERAGE_RATING;
    public constructor(public payload: number) {}
}

export type RatingActions = SetAverageRating;
