import { Injectable, OnDestroy} from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Storage } from '@capacitor/storage';
import { Store } from '@ngrx/store';
import { TokenService } from './token.service.util';
import { ConfigUtil } from './config.util';
import * as AuthActions from './store/auth.actions';

export interface AuthData {
    accessToken: string;
    authTokenExpirationTime: any;
}

@Injectable({
    providedIn: 'root'
})

export class AuthServiceUtil implements OnDestroy {
    private user = new BehaviorSubject<TokenService>(null);
    private activeLogoutTimer: any;

    public constructor(
        private fpnAuthStore: Store<{ fpnAuth: { userData: any } }>,
    ) {}

    public ngOnDestroy() {
        if (this.activeLogoutTimer) {
          clearTimeout(this.activeLogoutTimer);
        }
    }

    public userIsAuthenticated() {
        return from(Storage.get({key: ConfigUtil.authDataKey}))
                .pipe(map((storedData: any) => {
                    if (!storedData || !storedData.value) {
                        return null;
                }
                const parseStoredData = JSON.parse(storedData.value);
                const user = new TokenService(parseStoredData);
                this.fpnAuthStore.dispatch(new AuthActions.SetAuthData(JSON.parse(storedData.value)));
                if (user) {
                    return !!user.getAuthToken();
                } else {
                    return false;
                }
        }));
    }

    public autoLogin() {
        return from(Storage.get({key: ConfigUtil.authDataKey}))
            .pipe(map((storedData: any) => {
                if (!storedData || !storedData.value) {
                    return null;
                }
                const parsedData = JSON.parse(storedData.value);

                if (parsedData.authTokenExpirationTime <= new Date().getTime()) {
                    return null;
                }

                const user = new TokenService(parsedData);
                return user;
            }),
            tap(user => {
                if (user) {
                    this.user.next(user);
                    this.autoLogout(user.getAuthTokenDuration());
                }
            }),
            map(user => !!user)
            );
    }

    public setAuthData(userData: AuthData) {
        const expirationTime = new Date().getTime() + +userData.authTokenExpirationTime;
        userData.authTokenExpirationTime = expirationTime;
        const auth = new TokenService(userData);
        
        this.user.next(auth);
        this.autoLogout(auth.getAuthTokenDuration());
        this.fpnAuthStore.dispatch(new AuthActions.SetAuthData(userData));
        this.storeAuthData(userData);

    }

    public removeAuthData() {
      if (this.activeLogoutTimer) {
          clearTimeout(this.activeLogoutTimer);
      }
      this.user.next(null);
      this.fpnAuthStore.dispatch(new AuthActions.RemoveAuthData());
      Storage.remove({key: ConfigUtil.authDataKey});
  }

    private storeAuthData(userData: any) {
        const data = JSON.stringify(userData);
        Storage.set({key: ConfigUtil.authDataKey, value: data});
    }

    private autoLogout(duration: number) {
        if (this.activeLogoutTimer) {
            clearTimeout(this.activeLogoutTimer);
        }

        this.activeLogoutTimer = setTimeout(() => {
            this.removeAuthData();
        }, duration);
    }
}