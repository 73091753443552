import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { Subscription } from 'rxjs';
import { AuthServiceUtil } from '../../utils/auth.service.util';
import { ConfigUtil } from './../../../common/utils/config.util';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  public isAuthenticated = false;
  public iconInterval: any;
  public iconClass = 'default-icon';
  public themeTitle = 'Light theme';
  public isThemeToggleChecked = false;
  private totalNotifications: number;
  private iconState = false;
  private authSubscription: Subscription;

  public constructor(
    private router: Router,
    private renderer: Renderer2,
    private alertCtrl: AlertController,
    private authServiceUtil: AuthServiceUtil,
  ) { }

  public ngOnInit() {
    this.authSubscription = this.authServiceUtil.userIsAuthenticated().subscribe(isAuth => {
      if (!isAuth) {
        this.isAuthenticated = false;
      } else {
        this.isAuthenticated = true;
      }
    });
    this.totalNotifications = 10;
    if (this.totalNotifications > 0) {
      this.iconInterval = setInterval(() => {
        this.iconState = !this.iconState;
        this.iconClass = (this.iconState ? 'active-icon' : 'default-icon');
      }, 1000);
    }

    Storage.get({ key: ConfigUtil.colorThemeKey }).then((res) => {
      this.setThemeColor(res.value);
    });
  }

  public ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  public async toggleThemeColor(event: any) {
    if(event.detail.checked) {
      Storage.set({key: ConfigUtil.colorThemeKey, value: 'dark'});
      const retrive = await Storage.get({ key: ConfigUtil.colorThemeKey });
      this.setThemeColor(retrive.value);
    } else {
      Storage.set({key: ConfigUtil.colorThemeKey, value: 'light'});
      const retrive = await Storage.get({ key: ConfigUtil.colorThemeKey });
      this.setThemeColor(retrive.value);
    }
  }

  public onLogout() {
    this.alertCtrl.create(
      {header: 'Logout',
       message: 'Are you sure want to logout?',
       buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => true
        },
        {text: 'Yes',
          handler: () => {
            this.authServiceUtil.removeAuthData();
            return this.router.navigateByUrl(ConfigUtil.authUrl);
          }
        }
       ]}).then(alertEl => {
         alertEl.present();
       }).catch((errors: any) => {

       });
  }

  private setThemeColor(themeColor: string) {
    this.renderer.setAttribute(document.body, 'color-theme', themeColor);
    if(themeColor === 'dark') {
      this.isThemeToggleChecked = true;
      this.themeTitle = 'Dark theme';
    } else {
      this.isThemeToggleChecked = false;
      this.themeTitle = 'Light theme';
    }
  }

}
