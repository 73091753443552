export const ConfigUtil = {
    authDataKey: 'fpnAppAuthData',
    orgDataKey: 'fpnMobileOrgData',
    colorThemeKey: 'fpnColorTheme',
    landingUrl: '/',
    authUrl: '/account',
    homeUrl: '/home',
    profileUrl: '/profile',
    profileStatusUrl: '/profile/status',
    notificationUrl: '/profile/notification',
    manageCauseUrl: '/cause/manage',
    manageOrgUrl: '/org/manage',
    manageFixitUrl: '/fixit/manage',
    manageGroupUrl: '/group/manage',
    manageMeUrl: '/legacy/me/manage',
    manageForumUrl: '/legacy/forum/manage',
    manageMusicUrl: '/legacy/music/manage',
    manageVideoUrl: '/legacy/video/manage',
    manageShopUrl: '/legacy/shop/manage',
    causeUrl: '/cause',
    manageMe: '/legacy/me/manage',
    musicUrl: '/legacy/music',
    productUrl: '/legacy/shop',
    forumUrl: '/legacy/forum',
    groupUrl: '/group',
    orgUrl: '/org',
    menuList: [
        {pageName: 'Causes', icon: 'folder', url: 'cause'},
        {pageName: 'Buy/Sell', icon: 'cart', url: 'store'},
        {pageName: 'Corporate', icon: 'globe', url: 'corporate'},
        {pageName: 'Play for Change', icon: 'headset-outline', url: 'music'},
        {pageName: 'Stream with your Idol', icon: 'videocam-outline', url: 'video'},
        {pageName: 'Forum', icon: 'people-outline', url: 'forum'}
    ],

    getDate: [
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15',
        '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
    ],

    getMonth: [
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
    ],
    statusOptions: [ 'Publish', 'Draft']
};
