import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { Storage } from '@capacitor/storage';
import { SplashScreen } from '@capacitor/splash-screen';
import { Subscription } from 'rxjs';
import { AuthServiceUtil } from './common/utils/auth.service.util';
import { ConfigUtil } from './common/utils/config.util';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private authSubscription: Subscription;
  private previousAuthState = false;
  private connectionIntervalId = null;

  public constructor(
    private renderer: Renderer2,
    private toastCtrl: ToastController,
    private platform: Platform,
    private router: Router,
    private authServiceUtil: AuthServiceUtil,

  ) {
    this.initializeApp();
  }

  public ngOnInit() {
    this.authSubscription = this.authServiceUtil.userIsAuthenticated().subscribe(isAuth => {
      if (!isAuth && this.previousAuthState !== isAuth) {
        this.router.navigateByUrl(ConfigUtil.authUrl);
      }
      this.previousAuthState = isAuth;
      console.log(this.previousAuthState, '=', isAuth);
    });
  }

  public ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  private initializeApp() {
    this.platform.ready().then(async () => {
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        await SplashScreen.hide();
      }
    });

    Storage.get({ key: ConfigUtil.colorThemeKey }).then((res) => {
      //this.setThemeColor(res.value);
      this.renderer.setAttribute(document.body, 'color-theme', res.value);
    });

    //this.connectionIntervalId = setInterval(() => {
      //this.getNetworkStatus();
    //}, 10000);

  }

  private getNetworkStatus() {
    Network.getStatus().then((res) => {
      console.log(res.connected);
      if(!res.connected) {
        this.toastCtrl.create({
          message: '<ion-icon name="wifi-outline"></ion-icon> No Internet connection',
          position: 'top',
          color: 'danger',
          duration: 10000,
          buttons: [
          {
            text: 'OK',
            role: 'cancel'
          }
        ]
        }).then(toastEl => {
            toastEl.present();
        });
      } else {
        this.connectionIntervalId = null;
        this.toastCtrl.create({
          message: '<ion-icon name="wifi-outline"></ion-icon> Connected to internet.',
          position: 'top',
          color: 'success',
          duration: 10000,
          buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: () => {
              this.connectionIntervalId = null;
            }
          }
        ]
        }).then(toastEl => {
            this.connectionIntervalId = null;
            toastEl.present();
        });
      }
    });
  }
}
