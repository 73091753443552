import { Action } from '@ngrx/store';

export const ADD_WISHLIST_TOTAL = 'ADD_WISHLIST_TOTAL';
export const DELETE_WISHLIST_TOTAL = 'DELETE_WISHLIST_TOTAL';

export class AddWishlistTotal implements Action {
  public readonly type = ADD_WISHLIST_TOTAL;
  public constructor(public payload: number) {}
}

export class DeleteWishlistTotal implements Action {
  public readonly type = DELETE_WISHLIST_TOTAL;
  public constructor(public payload: number) {}
}

export type WishlistActions = AddWishlistTotal | DeleteWishlistTotal;
