import * as CartActions from './cart.actions';

const initialState = {
  cartTotalQuantity: 0,
};

export const cartReducer = (state = initialState, action: CartActions.CartActions) => {
  switch(action.type) {
    case CartActions.ADD_CART_TOTAL:
      return {...state, cartTotalQuantity: action.payload};
      break;
    case CartActions.DELETE_CART_TOTAL:
      return {...state, cartTotalQuantity: action.payload};
      break;
    default:
      return {...state};
  }
};
