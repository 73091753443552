import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './common/components/nav-menu/nav-menu.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from './../environments/environment';
import { InterceptorService } from './common/utils/interceptor.service';
import { authReducer } from './common/utils/store/auth.reducer';
import { cartReducer } from './cart/store/cart.reducer';
import { wishlistReducer } from './wish-list/store/wishlist.reducer';
import { ratingReducer } from './common/components/rating/store/rating.reducer';
//import { ServiceWorkerModule } from '@angular/service-worker';


@NgModule({
    declarations: [
      AppComponent,
     NavMenuComponent,
   ],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        StoreModule.forRoot({
            fpnAuth: authReducer,
            fpnCart: cartReducer,
            fpnWishlist: wishlistReducer,
            fpnRating: ratingReducer
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
